.create-trendy {
  padding: 24px 58px;
}

.back-btn {
  display: flex;
  align-items: center;
  font-size: 25px;
}

.back-btn__text {
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-left: 16px;
  cursor: pointer;
}

.back-btn__content {
  margin-left: 14px;
}

.form-create {
  margin: 50px;
  font-size: 22px;
  font-weight: 500;
}

.font-weight{
  font-weight: 400 !important;
}

.form__input {
  width: 100%;
  border: 1px solid #26b6e3;
  padding: 8px 8px;
  font-size: 16px;
  border-radius: 5px;
  outline: none;
}

.form__label {
  display: block;
  margin-bottom: 36px;
  margin-top: 16px;
}

.form__radio-label {
  font-size: 22px !important;
  font-weight: 500 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  color: #000 !important;
}

.save-btn {
  float: right;
  margin-bottom: 50px !important;
  background-color: #26b6e3 !important;
}

.form-create__text-field {
  margin-bottom: 30px !important;
}

.error-message {
  font-size: 12px;
  color: #d32f2f;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 4px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}
.textUrl > div > input {
  padding-left: 209px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  /* font-family: none; */
}

.textUrl{
  position: relative;
}
.spanUrl{
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  position: absolute;
  font-size: 16px;
  font-weight: 370;
  padding-top: 12px;
  padding-left: 8px;
  color: rgba(0,0,0,0.87);
}

@media only screen and (max-width: 768px) {
  .create-trendy {
    padding: 0;
  }

  .form-create {
    margin: 30px;
    font-size: 22px;
    font-weight: 500;
  }
}
