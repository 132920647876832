.table-header {
  font-size: 18px !important;
  font-weight: bold !important;
}

.table-header-no {
  width: 20px !important;
}

.table-blog-title {
  text-decoration: none;
  text-align: center;
  color: #000;
}

.table-blog-title:hover {
    text-decoration: underline;
}
