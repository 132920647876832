.about__company {
  display: flex;
  align-items: center;
  margin-left: 16px;
}

.about__company-name {
  margin-left: 18px;
  font-weight: 700;
  font-size: 20px;
}

.about__company-avatar img {
  object-fit: contain;
}

.list-tabs {
  margin-top: 16px;
}

.list-tabs__tab {
  width: 50%;
}

.about-tab {
  margin-top: 30px;
}

.about-tab__company-name {
  font-weight: 800;
  margin-bottom: 20px;
  font-size: 24px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.about-tab__company-info {
  font-weight: 500;
  margin-top: 4px;
}

.facebook-icon {
  color: #1877f2;
  font-size: 44px !important;
  margin: 8px;
}

.web-icon {
  font-size: 44px !important;
  color: #26b6e3;
  margin: 8px;
}

.instagram-icon {
  font-size: 44px !important;
  margin: 8px;
}

.about-tab__social {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.video-tab {
  margin-top: 16px;
  padding: 0 !important;
}

.video-tab__video {
  display: flex;
  margin-top: 24px;
  cursor: pointer;
}

.video-tab__video-iframe {
  aspect-ratio: 3/2;
  background-color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-tab__play-icon {
  font-size: 60px !important;
  color: #fff;
}

.video-tab__play-icon:hover {
  transition: background-color 0.3s, color 0.3s ease-in-out;
  color: #cfa22f;
}

.video-info__title {
  font-weight: 600;
  font-size: 20px;
}

.video-info__sub-title {
  color: rgba(0, 0, 0, 0.51);
  font-size: 14px;
}

.about-tab__info-label {
  font-weight: 600;
}

.MuiTabs-flexContainer {
  justify-content: space-around;
}

@media only screen and (max-width: 768px) {
  .video-info__title {
    font-size: 18px;
  }

  .video-tab__play-icon {
    font-size: 40px !important;
    color: #fff;
  }
}
