.tags{
    padding: 7px 10px; 
    background-color: #E9E9E9;
    border: 1px #000 solid;
    border-radius: 5px;
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 14px;
    font-weight: 400;
}
.content-tags{
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}
.title-tag{
    padding-top: 30px;
}