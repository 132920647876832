.account {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -999;
}

.account-form {
  width: 500px;
  font-weight: 600;
}

.account-form__btn {
  float: right;
  margin-top: 40px !important;
  background-color: #26b6e3 !important;
}

.account-form__item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.account-form__icon-edit {
  font-size: 16px !important;
  color: #000;
}

@media only screen and (max-width: 768px) {
    .account {
        margin: 0 16px;
    }
}
