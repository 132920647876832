.user-layout {
  padding: 24px;
  max-width: 80vw;
  margin: auto;
}

@media only screen and (max-width: 768px) {
  .user-layout {
    max-width: 100vw;
  }
}
