.detail__back-btn {
  display: flex;
  align-items: center;
}

.back-btn__text {
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.back-btn__content {
  margin-left: 14px;
}

.detail__content {
  margin-top: 24px;
}

.content__title {
  font-size: 24px;
  font-weight: 700;
  padding-left: 0.6rem;
  margin-top: 16px;
}

.detail__image {
  width: 100%;
  border-radius: 6px;
  margin-top: 20px;
}

.divider {
  border-color: #000;
}

.list-tag__footer {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.list-tag__footer-tag {
  margin: 16px;
}

.video-tag-title {
  margin-top: 30px;
  font-weight: 500;
  font-size: 24px;
}

.list-video {
  margin-top: 33px;
}

.list-video__video-iframe {
  width: 100%;
  aspect-ratio: 3 / 2;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.list-video__title {
  display: flex;
  align-items: center;
  margin-top: 4px;
}

.list-video__title-text {
  margin-left: 12px;
  cursor: pointer;
}

.title__main {
  font-size: 18px;
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.title__sub {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.51);
}

.list-video__play-icon {
  font-size: 60px !important;
  color: #fff;
}

.list-video__title-avatar {
  border: 1px solid #ccc;
  cursor: pointer;
}

.search__input{
  border: 1px #D1D1D1 solid;
  background-color: #fff !important;
  box-shadow: 0px 3px 3px 1px rgba(0, 0, 0, 0.25) !important;
  width: 100%;
  border-radius: 1px;
  height: 42px;
  padding-left: 10px;
}

img {
  border: 1px solid #eee;
  border-radius: 6px;
}
.notify{
  text-align: center;
  font-weight: 300;
}