.login-page {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    max-width: 900px;
}

.login-page__logo {
    height: 120px;
    margin-right: 100px;
    margin-bottom: 100px;
    border: none;
}

.form-create__text-field {
    min-width: 100px;
}

.btn-login {
    display: block;
    width: 100%;
    margin-top: 20px;
    background-color: #26B6E3 !important;
}

@media only screen and (max-width: 768px) {
    .login-page{
        flex-direction: column;
        justify-content: center;
    }

    .login-page__logo {
        height: 80px;
        margin-right: 0;
        margin-bottom: 0;
    }
}