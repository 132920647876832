.header {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
}
.header > div:last-child {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap:10px
}

.header__sandwich {
  color: #000 !important;
  margin: 8px 16px 0 0 !important;
  padding: 0 !important;
  min-width: 24px !important;
}

.header__text-logo {
  font-weight: 600;
  font-size: 33px;
  color: #26b6e3;
  line-height: 33px;
  text-decoration: none;
}

.menu-text {
  margin-left: 8px;
  font-size: 16px;
  font-weight: 500;
}

.menu-link {
  text-decoration: none;
  color: #000;
}
.img-user{
  width: 30px;
  border-radius: 50%;
}
.img-create{
  border: none;
  border-radius: 0%;
}
.img-sign-out{
  border: none;
  width: 20px;
  height: 20px;
}
.css-130dmu7-MuiTypography-root {
  padding: 3px !important;
  border: 1px #000 solid;
}

