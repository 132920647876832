.admin-header {
  background: linear-gradient(
    90deg,
    #26b6e3 -1.94%,
    #ceeaff 51.68%,
    #26b6e3 100%
  );
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
}

.logo {
  margin-right: 20px !important;
  font-weight: 700 !important;
  font-size: 40px !important;
}

.logout-btn {
  cursor: pointer;
}
