.trendy {
  margin: 8px;
  margin-top: 24px;
  margin-bottom: 40px;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.trendy__image {
  padding-top: 60%;
  border-radius: 5px;
  background-color: #ccc;
}

.trendy__title {
  font-size: 21px;
  font-weight: 900;
  margin: 8px 0;
}

.trendy__content {
  padding: 8px;
}

.trendy__summary {
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 300;
  font-size: 16px;
  margin-top: 16px;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.trendy__summary strong {
  font-weight: 300;
  font-size: 16px;
}

.trendy-list-tag__footer {
  display: flex;
  justify-content: flex-start;
  margin: 20px 0;
}

.trendy-list-tag__footer-tag {
  margin-right: 28px;
  font-weight: 500;
  font-size: 12px;
}
