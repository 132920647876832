.tag {
  margin: 8px 0;
  overflow-x: auto;
}

.tag__text {
  font-weight: 600;
}

.tag__text-active {
  background-color: #272727 !important;
  color: #fff !important;
}
