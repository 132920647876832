.video-title {
  margin-top: 30px;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 24px;
}

.video-detail__iframe {
  width: 100%;
  height: 90vh;
  border: none;
  border-radius: 8px;
}

.video-detail__divider {
  border-color: #000 !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.video-detail__tag-list {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.video-detail__company-name {
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;
}

.video-detail__company-info {
  line-height: 24px;
}

.see-company {
  margin-top: 30px;
  float: right;
  margin-bottom: 30px;
  font-weight: 500;
  cursor: pointer;
  border: 1px solid #000;
  padding: 6px 10px;
  border-radius: 8px;
}

.see-company:hover {
  text-decoration: underline;
}
.video-detail__video{
  padding-top: 20px;
}

@media only screen and (max-width: 768px) {
  .video-detail__iframe {
    width: 100%;
    aspect-ratio: 5/8;
    border: none;
  }
}
