#editorjs {
  border: 1px solid #000;
  font-size: 16px;
}

.ce-block__content {
  max-width: 90%;
}

.error-editor {
  border: 1px solid #d32f2f;
  border-radius: 4px;
}
