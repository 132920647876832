.divider {
  border-color: #000 !important;
}

.list-video {
  margin-top: 33px;
}

.list-video__video-iframe {
  width: 100%;
  aspect-ratio: 3 / 2;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.list-video__video{
  position: relative;
}

.list-video__title {
  display: flex;
  align-items: center;
  margin-top: 4px;
}

.list-video__title-text {
  margin-left: 12px;
  cursor: pointer;
}

.title__main {
  font-size: 18px;
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.title__sub {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.51);
  margin-top: 4px;
  margin-left: 60px;
}

.list-video__play-icon {
  font-size: 80px !important;
  color: #fff;
}

.list-video__play-icon:hover {
  transition: background-color 0.3s, color 0.3s ease-in-out;
  color: #cfa22f;
}

.list-video__title-avatar {
  border: 1px solid #ccc;
  margin-top: 8px;
  cursor: pointer;
}

.list-video__title-avatar img {
  object-fit: contain;
}
.img-star{
  border: none;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
}

.div-star{
  z-index: 99;
}