.divider {
  border-color: #000 !important;
}

.footer-tag {
    margin-top: 12px !important;
    margin-bottom: 40px;
    text-align: center;
}

.footer-tag__text {
    font-size: 12px;
}

.list-trendy__title {
  font-weight: 500;
  font-size: 24px;
}