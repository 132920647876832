.content {
    padding: 40px 71px;
  }

@media only screen and (max-width: 768px) {
  .content {
    padding: 40px 10px;
  }
}

.button-create {
  background-color: #26b6e3 !important;
  float: right;
  margin-bottom: 30px !important;
}
