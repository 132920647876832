.search-form {
  margin-bottom: 18px !important;
  padding: 2px 4px;
  display: flex;
  align-items: center;
  border: 2px solid #000;
  height: 36px;
  background-color: #fff !important;
  box-shadow: 0px 3px 3px 1px rgba(0, 0, 0, 0.25) !important;
  border-radius: 10px !important;
}

.search-icon {
  padding: 10px;
  color: #000 !important;
}

.search-form__input {
  margin-left: 10px;
  flex: 1;
}
