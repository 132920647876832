.box-login{
    width: 200px;
    padding: 10px 16px 10px 16px !important;
    text-align: center;
}
.box-login > p{
    margin: 0;
}
.box-login > div{
    padding-top: 20px;
}
.box-login > div >u{
    font-weight: 600;
}
.div-star{
    z-index: 90;
}
.img-star{
    z-index: 99;
}
.css-3bmhjh-MuiPaper-root-MuiPopover-paper {
    box-shadow: none !important;
}
